<template>
    <div class="hero-banner university-banner-section bg-image">
        <div class="container edublink-animated-shape">
            <div class="row">
                <div class="col-xl-6 col-lg-7">
                    <div class="banner-content">
                        <span class="subtitle">Explore lika</span>
                        <h1 class="title text-uppercase">{{ $t('hero') }}</h1>
                        <div class="banner-btn">
                            <NuxtLink to="#content" class="edu-btn btn-secondary hero-button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M12.1777 13.6088C11.8218 13.6095 11.4693 13.5399 11.1404 13.4039C10.8115 13.268 10.5127 13.0684 10.2612 12.8166L0.508762 3.06419C0.178932 2.72347 -0.00375965 2.26676 9.01493e-05 1.79257C0.00393994 1.31837 0.194023 0.864689 0.529341 0.529371C0.864659 0.194053 1.31834 0.00397046 1.79254 0.000120667C2.26673 -0.00372913 2.72344 0.178962 3.06416 0.508792L11.9968 9.44144C12.0205 9.46524 12.0487 9.48412 12.0798 9.497C12.1108 9.50988 12.1441 9.51652 12.1777 9.51652C12.2113 9.51652 12.2446 9.50988 12.2757 9.497C12.3067 9.48412 12.3349 9.46524 12.3587 9.44144L21.2913 0.508792C21.632 0.178962 22.0887 -0.00372913 22.5629 0.000120667C23.0371 0.00397046 23.4908 0.194053 23.8261 0.529371C24.1614 0.864689 24.3515 1.31837 24.3554 1.79257C24.3592 2.26676 24.1765 2.72347 23.8467 3.06419L14.0943 12.8166C13.8427 13.0684 13.5439 13.268 13.215 13.4039C12.8861 13.5399 12.5336 13.6095 12.1777 13.6088Z" fill="white"/>
                                    <path d="M12.1777 24.0001C11.8218 24.0008 11.4693 23.9311 11.1404 23.7952C10.8115 23.6593 10.5127 23.4597 10.2612 23.2079L0.508762 13.4555C0.178932 13.1148 -0.00375965 12.658 9.01493e-05 12.1838C0.00393994 11.7097 0.194023 11.256 0.529341 10.9207C0.864659 10.5853 1.31834 10.3953 1.79254 10.3914C2.26673 10.3876 2.72344 10.5702 3.06416 10.9001L11.9968 19.8327C12.0205 19.8565 12.0487 19.8754 12.0798 19.8883C12.1108 19.9012 12.1441 19.9078 12.1777 19.9078C12.2113 19.9078 12.2446 19.9012 12.2757 19.8883C12.3067 19.8754 12.3349 19.8565 12.3587 19.8327L21.2913 10.9001C21.632 10.5702 22.0887 10.3876 22.5629 10.3914C23.0371 10.3953 23.4908 10.5853 23.8261 10.9207C24.1614 11.256 24.3515 11.7097 24.3554 12.1838C24.3592 12.658 24.1765 13.1148 23.8467 13.4555L14.0943 23.2079C13.8427 23.4597 13.5439 23.6593 13.215 23.7952C12.8861 23.9311 12.5336 24.0008 12.1777 24.0001Z" fill="white"/>
                                </svg>
                            </NuxtLink>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="shape-group">
                <MouseMove addClassName="shape-1" dataDepth="2" data-aos-delay="200" data-aos="fade" data-aos-duration="200"
                    imgSrc="/images/others/shape-10.png" />
                <MouseMove addClassName="shape-2" dataDepth="-3" data-aos-delay="200" data-aos="fade"
                    data-aos-duration="200" imgSrc="/images/others/shape-11.png" />
                <li class="shape-3">
                    <img src="/images/others/shape-25.png" alt="Shape">
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import MouseMove from '../animation/MouseMove.vue';


export default {
    components: {
        MouseMove,
    },
}
</script>


<style lang="scss">
.university-banner-section {
    min-height: 715px;
    position: relative;
    z-index: 1;
    mask-image: url('/images/others/mask-01.png');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    -webkit-mask-position: center;

    @media only screen and (min-width: 768px) and (max-width: 991px){
        min-height: 100%;
        padding: 100px 0 55px;
    }
    @media only screen and (max-width: 767px){
        min-height: 100%;
        padding: 80px 0 35px;
    }
    &:before {
        content: "";
        height: 100%;
        width: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .banner-content {
        padding-bottom: 110px;

        .title {
            color: var(--color-white);
            font-weight: var(--p-extra-bold);
        }

        p {
            color: var(--color-white);
            font-size: 18px;
        }
    }

    .shape-group {
        list-style: none;
        margin: 0;
        padding: 0;

        @media only screen and (min-width: 768px) and (max-width: 991px){
            display: none;
        }
        @media only screen and (max-width: 767px){
            display: none;
        }
        li {
            margin: 0;
            position: absolute;
            z-index: -1;

            &.shape-1 {
                top: -122px;
                left: -207px;
            }

            &.shape-2 {
                top: -108px;
                left: 142px;
            }

            &.shape-3 {
                top: -410px;
                left: -516px;
            }
        }
    }
}</style>