<template>
	<div>
        <Head>
            <Title>{{ $t('meta.index.title') }}</Title>
            <Meta name="og:title" :content="$t('meta.index.title')" />
            <Meta name="description" :content="$t('meta.index.description')" />
            <Meta name="og:description" :content="$t('meta.index.description')" />
        </Head>
    </div>
	<div id="main-wrapper" class="main-wrapper">
		<CustomComponentsHero />
		<span id="content"></span>
		<CustomComponentsCarousel
			:items="recomendedList"
			:carouselTitle="{ 'en': 'Recommendations', 'hr': 'Preporuke' }"
			:index="5607"
			:key="345678"
		/>
		<CustomComponentsCarousel v-for="(category, index) in selectedCategories"
			:category="category"
			:showSeeMore="true"
			:seeMoreLink="{path: '/locations', query: { filter: category } }"
			:carouselTitle="getCategoryName(category)"
			:index="index"
			:key="category"
		/>
  	</div>
</template>

<script setup>
	import { ref } from 'vue'
	import { useMainStore, useLanguageStore } from '~/store'

	const store = useMainStore()
	const languageStore = useLanguageStore()
	const { locale } = useI18n()
	const language = ref(locale.value)
	const selectedCategories = ref({})
	const recomendedList = ref([])

	selectedCategories.value = store.getSelectedCategories
	recomendedList.value = store.getRecommendations

	store.$subscribe((mutation, state) => {
		if (store.storeCompletelyLoaded) {
			selectedCategories.value = store.getSelectedCategories
			recomendedList.value = store.getRecommendations
		}
	})

	languageStore.$subscribe((mutation, state) => {
		if (mutation.type == 'patch object' && mutation.payload.currentLanguage) {
			language.value = mutation.payload.currentLanguage
		}
	})

	function getCategoryName(categoryId) {
		return store.getCategoryNameByCategory(categoryId)
	}
</script>